<template>
  <div>

    <el-popover
      placement="right"
      width="600"
      trigger="click"
    >
      <div>

        В данном типе шкал можно использовать формулы.<br><br>
        В первой и второй колонке мы заполняем процент выполнения цели ОТ и ДО соответственно.<br>
        Если заполнено ДО, но не заполнено ОТ, то в этот диапазон попадет все, что ниже ДО<br>
        Если заполнено ОТ, но не заполнено ДО, то в этот диапазон попадет все, что выше ОТ<br><br>
        В третьей колонке мы задаем процент премии в этом диапазоне достижения цели.<br>
        Можно использовать как просто числа для фиксированного процента премии, так и переменные.<br><br>
        Доступно 3 перемеренных<br>
        FROM - значение диапазона ОТ<br>
        TO - значение диапазона ДО<br>
        PERCENT - процент выполнения цели<br><br>
        Например, мы хотим следующий критерий<br>
        При достижении цели от 80 до 110 процентов, дополнительно выплачивать 3 процента премии за каждый процент в этом диапазоне.<br>
        Пусть сотрудник выполнил цель на 94 процента.<br>
        Тогда в математическом выражении это выглядит следующим образом 80+(94-80)*3<br><br>
        Чтобы реализовать такой критерий заполните его следующим образом:<br>
        ОТ = 80<br>
        ДО = 110<br>
        Формула = FROM+(PERCENT-FROM)*3<br>

      </div>

      <el-button
        slot="reference"
        size="mini"
      >Инструкция к заполнению критериев</el-button>
    </el-popover>



    <el-table-draggable handle=".scope-grab" @drop="orderChanged">
      <el-table
        size="mini"
        empty-text="Нет критериев"
        :data="scale.scopes"
      >
        <el-table-column width="30" v-if="!disabled">
          <template v-slot="scope">
            <i class="fas fa-arrows-alt-v scope-grab"></i>
          </template>
        </el-table-column>

        <el-table-column label="(Факт / план * 100)% ОТ" width="200">
          <template v-slot="scope">
            <click-to-edit
              v-model="scope.row.from"
              placeholder=""
              :disabled="disabled"
              style="width: 100%"
              digits-only
              @input="saveScope(scope.row)"
            ></click-to-edit>
          </template>
        </el-table-column>
        <el-table-column label="(Факт / план * 100)% ДО" width="200">
          <template v-slot="scope">
            <click-to-edit
              v-model="scope.row.to"
              placeholder=""
              :disabled="disabled"
              style="width: 100%"
              digits-only
              @input="saveScope(scope.row)"
            ></click-to-edit>
          </template>
        </el-table-column>

        <el-table-column label="Формула">
          <template v-slot="scope">
            <click-to-edit
              v-model="scope.row.formula"
              placeholder=""
              :disabled="disabled"
              style="width: 100%"
              @input="saveScope(scope.row)"
            ></click-to-edit>
          </template>
        </el-table-column>

        <el-table-column width="45" align="right" v-if="!disabled">
          <template v-slot="scope">
            <el-button
              class="table-action-button"
              size="mini"
              round
              icon="far fa-trash-alt"
              @click="deleteScope(scope.row)"
            >
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-table-draggable>
    <el-button
      v-show="!disabled"
      type="text"
      size="mini"
      icon="fas fa-plus"
      @click="$emit('add-scope')"
    >
      Создать критерий
    </el-button>
  </div>
</template>

<script>
import ElTableDraggable from "element-ui-el-table-draggable";
import {configurableScaleScopes} from "@/mixins/configurableScaleScopes";
import ClickToEdit from "@/components/ClickToEdit.vue";

export default {
  name: "quantity-formula-scopes",
  components: {ClickToEdit, ElTableDraggable},
  mixins: [configurableScaleScopes],
  props: {},
  watch: {},
  data() {
    return {}
  },
  mounted() {
  },
  methods: {}
}
</script>


<style lang="scss" scoped>
.scope-grab {
  padding: 0 7px;
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

/* (Optional) Apply a "closed-hand" cursor during drag operation. */
.scope-grab:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
</style>